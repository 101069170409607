import { Modal, Button } from "@myob/myob-widgets"
  
  
export default function RevokeAccessModal({ deleteConsent, setShowModal, client, showModal }) {
	return <>{
		showModal && 
		<Modal size="small" title="Revoke access?" onCancel={() => setShowModal(false)}>
			<Modal.Body>
				<p>
					Removing access to <b>{client.clientName}</b> will mean they no longer have access to this account.
				</p>
				<div> 
					Functionality related to this third-party will also be removed. 
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => setShowModal(false)} tone="neutral" variant="standard">Go back</Button>
				<Button onClick={() => deleteConsent()} tone="danger" variant="standard">Revoke Access</Button>
			</Modal.Footer>
		</Modal>
	}</>
}