import { Button, ButtonRow, Modal } from '@myob/myob-widgets';

type ModalProps = {
  onAction: () => void;
  onGoBack: () => void;
  title?: string;
  message?: string;
};

export const ModalBox: React.FC<ModalProps> = ({ onAction, onGoBack, ...props }) => {
  const { title, message } = props;

  return (
    <Modal title={`${title}?`} onCancel={onGoBack}>
      <Modal.Body className="modal-body">{message}</Modal.Body>
      <Modal.Footer>
        <ButtonRow>
          <Button type="secondary" onClick={onGoBack}>
            Cancel
          </Button>
          <Button type="delete" onClick={onAction}>
            {`${title}`}
          </Button>
        </ButtonRow>
      </Modal.Footer>
    </Modal>
  );
};
