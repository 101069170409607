import {useEffect, useState} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Card, BaseTemplate, PageHead, Spinner, Table, Box, ArrowLeftIcon, Alert } from "@myob/myob-widgets"
import bff from './effects/bff-client'
import config from './config';
import { Client, ConsentListResponse, DeleteConsentResponse } from '../types';
import { stringCompare } from './helper/string-compare';
import RevokeAccessModal from './components/revoke-modal';

const dangerNotification = {display: true, message: "An error occurred, please try again later", clientName: '', tone: 'danger'}

const tableColumns = [
	{ key: "clientName", description: "Applications", visible: true },
	{ key: "accessTo", description: "Access To", visible: true },
	{ key: "createdAt", description: "Added on", visible: false },
	{ key: "scope", description: "Access", visible: true },
];

function Integration() {
	const [showModal, setShowModal] = useState(false);
	const [client, setClient] = useState<Client>(null);
	const [notification, setNotification] = useState({display: false, message: '', clientName: '', tone: 'success'})
	const [isEmpty, setIsEmpty] = useState(false)
	const [loading, setLoading] = useState(true)
	const {getAccessTokenSilently: getAccessToken} = useAuth0()

	async function getConsent() {
		const token = await getAccessToken()
		const resp = await bff.get<ConsentListResponse[]>('/consent', {headers: {Authorization: `Bearer ${token}`}})
		setData(resp.data)
		if (resp.data.length === 0) {
			setIsEmpty(true)
		} else {
			setIsEmpty(false)
		}
		setLoading(false)
    }
	
	useEffect(() => {
		getConsent()
	}, [])

	async function deleteConsent() {
		try {
			const clientName = client.clientName
			const token = await getAccessToken()
			const resp = await bff.delete<DeleteConsentResponse>(`/client/${client.clientId}/consent`, { headers: { Authorization: `Bearer ${ token }`}})
			setShowModal(false)
			await getConsent()
			setClient(null)
			if (resp.status === 204) {
				setNotification({display: true, message: 'Access revoked for', clientName: clientName, tone: 'success'})
			} else {
				setNotification(dangerNotification)
			}
		} catch(e) {
			setNotification(dangerNotification)
			console.error(e)
		}
	}

	async function openRevokeModal(data: Client) {
		setClient({...data});
		setShowModal(true);
	}

    const [sortDirection, setSortDirection] = useState("unsorted");
    const [sortName, setSortName] = useState("");
    const [data, setData] = useState([]);

    const sort = {
        clientName: (a: { clientName: string; }, b: { clientName: string; }) => stringCompare(a.clientName, b.clientName),
		createdAt: (a: { createdAt: string; }, b: { createdAt: string; }) => stringCompare(a.createdAt, b.createdAt),
    };

    const columns = tableColumns;

    const onSort = (column, nextSortDirection) => {
        setSortDirection(nextSortDirection);
        setSortName(column);
        setData(
        	applySort(data, sort[column], nextSortDirection === "descending")
        );
    };
      
	const applySort = (data, sortFn, descending) => {
		const result = data.slice();
		
		result.sort(sortFn);
		
		return descending ? result.reverse() : result;
	};

    const renderHeader = (
        <Table.Header>
          {columns.map((c) => (
            <Table.HeaderItem
				key={c.key}
				sortName={c.key}
				sortDirection={c.key === sortName ? sortDirection : "unsorted"}
				onSort={sort[c.key] && onSort}
			>
				{c.description}
            </Table.HeaderItem>
          ))}
        </Table.Header>
    );

    function formatScopes(scopes: string[]) {
        return scopes != null ? <>{scopes.map(scope => (<div key={scope}>{scope}</div>))}</> : <></>
    }

    function formatDate(addedOn: string) {
        return addedOn != null ? new Date(addedOn).toDateString().slice(4) : ""
    }

	const renderRow = (d) => (
		<Table.Row key={d.id} rowData={d}>
			<Table.RowItem columnName="Applications">{d.clientName}</Table.RowItem>
			<Table.RowItem columnName="Access To">{formatScopes(d.scope)}</Table.RowItem>
			<Table.RowItem columnName="Added On">{formatDate(d.createdAt)}</Table.RowItem>
			<Table.RowItem title={d.email} columnName="Access">
				<a href={config.callback + "/#/integration/#"} onClick={() => openRevokeModal(d)}>Revoke Access</a>
			</Table.RowItem>
		</Table.Row>
	);

	function tableDisplay() {
		return isEmpty ?
		<div>No integrations to display</div> :
		<Table variant="standard">
			{renderHeader}
			<Table.Body>{data.map(renderRow)}</Table.Body>
		</Table>
	}
    return (
        <div style={{ maxWidth: '990px', margin: '0 auto' }}>
            {
                <BaseTemplate>
					        <PageHead title="Third-party integrations"/>
                    <Box display="flex" paddingBottom="xs">
                        <ArrowLeftIcon color="brand"/>
                        &nbsp;
                        <a href={config.callback + "/#"}>Back to account security</a>
                    </Box>
					{
						notification.display &&
						<Alert tone="success" onDismiss={() => setNotification({display: false, clientName: '', message: '', tone: ''})}>
							{notification.message} {notification.clientName ? <strong>{notification.clientName}.</strong> : null}
						</Alert>
					}
					<Card>
						{loading ? 
						<Spinner size="medium"/> 
						:
						tableDisplay()}
					</Card>
				</BaseTemplate>
            }
			<RevokeAccessModal 
				client={client} 
				showModal={showModal} 
				setShowModal={setShowModal} 
				deleteConsent={deleteConsent}
			/>
        </div>
    )
}

export default Integration;
