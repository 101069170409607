import { PageHead, Card, Button, LockIcon, Tooltip, Text, Columns, Box } from "@myob/myob-widgets"
import bff from '../effects/bff-client'
import React, { useState } from "react"
import { useAuth0 } from "@auth0/auth0-react";
import { throttle } from 'lodash'
import { NotificationProps } from "../app";
import {trackChangePasswordClickEvent, trackResetPasswordConfirmationClickEvent} from "../helper/telemetry-helper";
import { ModalBox } from "./remove-pop-up-modal";

export default function ChangePassword({ setNotification }: NotificationProps) {
    const { user, getAccessTokenSilently } =
        useAuth0();
    const [modalAction, setModalAction] = useState({ display: false })

    const onChangePassword = throttle(changePassword(user, setNotification, getAccessTokenSilently), 1000)

    const showResetConfirmationDialog = () => {
        setModalAction({ display: true })
        trackChangePasswordClickEvent('Reset Password')
    }

    const handleGoBack = () => {
        setModalAction({ display: false })
    }

    const confirmResetPassword = () => {
        trackResetPasswordConfirmationClickEvent()
        onChangePassword()
        setModalAction({ display: false })
    }

    return <>
        <div data-testid="security-page">
            {modalAction.display && (
                <ModalBox
                    onAction={confirmResetPassword}
                    onGoBack={handleGoBack}
                    title={`Reset password`}
                    message={`A link will be sent to your email`}
                />
            )}
        </div>
        <Card
            header={<Card.Header
                child={<PageHead title="Login" />}/>}
            body={<Card.Body child={
                <>
                    {
                        <>
                            {loginTableData.map(row => (
                                <Box
                                    display="flex"
                                    flexDirection={[
                                    'column', 
                                    'row',
                                    'row',    
                                    'row',    
                                    'row',    
                                    ]}
                                    columnGap={[
                                    null, 
                                    null, 
                                    "sm", 
                                    "sm",
                                    "sm", 
                                    ]}
                                    rowGap={[
                                    "sm", 
                                    "sm", 
                                    null, 
                                    null, 
                                    null, 
                                    ]}
                                >
                                    <Box flexGrow="1">
                                        <Columns alignY="center" data-testid="password" key={row.type}>
                                            <Columns.Column span={["12", "12", "3", "3", "3"]}>
                                                <Box>{row.methodName}</Box>
                                            </Columns.Column>
                                            <Columns.Column span={["12", "12", "8", "8", "8"]}>
                                                <Box>{row.description}</Box>
                                            </Columns.Column>
                                            <Columns.Column span={["12", "12", "1", "1", "1"]}>
                                                <Box textAlign="right">
                                                    <Button type="link" onClick={showResetConfirmationDialog}>Reset</Button>
                                                </Box>
                                            </Columns.Column>
                                        </Columns>
                                    </Box>
                                </Box>
                            ))}
                        </>
                    }
                </>
            } />}
        />
    </>
}

function changePassword(user, setNotification, getAccessTokenSilently) {
    return () => {
        getAccessTokenSilently().then(token => {
            return bff.post(`/change-password`, { email: user.email }, { headers: { Authorization: 'Bearer ' + token } }).then(resp => {
                setNotification({ display: true, message: 'We\'ve sent you an email to reset your password', tone: 'success' })
            })
        }).catch((e) => {
            const message = 'If you didn\'t get a password reset email, check your spam folder or try again in a few minutes'
            setNotification({ display: true, message: message, tone: 'danger' })
        })
    }
}

const loginTableData = [
    {
        id: '1',
        type: 'password',
        methodName: <Box><Tooltip triggerContent={<LockIcon color="neutral" style={{ verticalAlign: "sub" }} />}>Password</Tooltip>&nbsp;&nbsp;<Text as="strong">Password</Text></Box>,
        description: <Text tone='neutral' as='span'>Keep your password private. Don’t share it with anyone</Text>,
        displayName: 'Password'
    }
];