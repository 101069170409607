import Cookies from 'js-cookie';
import { auth } from "@my-account/tools";

export const getMyobVisitorId = (): string => {
    return Cookies.get('myob_visitor_id') || '';
}

export const getUserId = (): string => {
    return auth.getUser()?.userId || ''
}
