import {Auth0Provider, AppState} from "@auth0/auth0-react";
import config from "./config";
import App from "./app";
import {useState} from "react";
import {defaultTo} from 'ramda';
import {HashRouter as Router, Routes, Route} from "react-router-dom";
import Integration from "./integration";
export type ResumableAppState = {action: 'INIT'|'RESUME_CHANGE_MFA'}

export default function Root(props) {
    const [appState, setAppState] = useState<ResumableAppState>({action: 'INIT'})
    const restoreAppStateWhenRedirectBack = (appState?: ResumableAppState): void => {

        const sa = defaultTo({action: 'INIT'})(appState)
        setAppState(sa)
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname,
        );
    };
    return <div style={{maxWidth: '990px', margin: '0 auto'}}>
        <Auth0Provider
            domain={config.domain}
            clientId={config.clientId}
            useRefreshTokens={true}
            useRefreshTokensFallback={true}
            authorizationParams={{redirect_uri: config.callback,
                acr_values: '',
                // DON"T DELETE THIS LINE, VERY IMPORTANT!!!!
                audience: 'https://myob-id-account-bff.svc.platform.myob.com/v1/'}}
            onRedirectCallback={restoreAppStateWhenRedirectBack}
        >
            <link rel="shortcut icon" type="image/x-icon" href={config.myAccountDomain + "/favicon.ico"} />
            <Router basename="/">
                <Routes>
                    <Route  path="/integration" element={<Integration />} />
                    <Route  path="/*" element={<App appState={appState}/>} />
                </Routes>
            </Router>
        </Auth0Provider>
    </div>
}
