const currentEnv = process.env.REACT_APP_CURRENT_ENV || 'local'

const bff = {
   local: "https://myob-id-account-bff-dev.svc.platform.myobdev.com",
   dev: "https://myob-id-account-bff-dev.svc.platform.myobdev.com",
   preprod: "https://myob-id-account-bff-preprod.svc.platform.myobdev.com",
   prod: "https://myob-id-account-bff.svc.platform.myob.com",
}
const clientIdMap = {
   local :  'FWJj2oy22fzUt7KCDKmGcSoKQsZ2tTYP',
   dev: 'FWJj2oy22fzUt7KCDKmGcSoKQsZ2tTYP',
   preprod: 'NvhpMCnxcSgAMoxSSa1F2nzJOwXH56nP',
   prod: 'ru5R0J8S2iyX1Pe6vdFERJzUGCcvDd56',
}
const domainMap = {
   local: 'dev.id.myob.com',
   dev: 'dev.id.myob.com',
   preprod: 'sit.id.myob.com',
   prod: 'id.myob.com'
}
const callbackMap ={
   local: 'http://localhost:8080',
   dev: 'https://sit.myaccount.myob.com/account/security',
   preprod: 'https://sit.myaccount.myob.com/account/security',
   prod: 'https://myaccount.myob.com/account/security'
}

const myAccountDomainMap = {
   local: 'http://localhost:8080',
   dev: 'https://sit.myaccount.myob.com',
   preprod: 'https://sit.myaccount.myob.com',
   prod: 'https://myaccount.myob.com'
}

const config = {
   bff: bff[currentEnv],
   currentEnv,
   clientId: clientIdMap[currentEnv],
   domain: domainMap[currentEnv],
   callback:  callbackMap[currentEnv],
   myAccountDomain: myAccountDomainMap[currentEnv],
}

export default config
