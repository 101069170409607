import { telemetry } from '@my-account/tools'
import { getMyobVisitorId, getUserId } from './tools'
import config from '../config'

type TrackProperties = {
    userId: string
    myobVisitorId: string
    timestamp: string
    action: string
    event: string
    url: string
    page: string
}

export const trackMFAClickEvent = (
    operation: string,// Add, Remove, Reset
    mfaType: string, // `SMS`, `Authenticator app`, `Email`,`First`
): void => {
    const buttonName = `${operation} MFA`
    const clickAction = `${operation.toLowerCase()} ${mfaType} MFA`

    trackClickEvent(buttonName, clickAction)
}

export const trackMFAConfirmationClickEvent = (
    operation: string,// Add, Remove, Reset
    mfaType: string, // `SMS`, `Authenticator app`, `Email`
): void => {
    const buttonName = `${operation} MFA`
    const clickAction = `confirm ${operation.toLowerCase()} ${mfaType} MFA`

    trackClickEvent(buttonName, clickAction)
}

export const trackChangePasswordClickEvent = (buttonName: string,): void => {
    trackClickEvent(buttonName, buttonName.toLowerCase())
}

export const trackResetPasswordConfirmationClickEvent = (): void => {
    trackClickEvent('Reset Password', 'confirm reset password')
}

export const identifyMFA = (enabledMFATypes: string[]): void => {
    const userId = getUserId()
    if (userId) {
        let enabledSMS = 'false'
        let  enabledEmail = 'false'
        let enabledTotp = 'false'

        enabledMFATypes.forEach((enabledMfaType) => {
            if (enabledMfaType == 'totp') {
                enabledTotp = 'true'
            } else if (enabledMfaType == 'email') {
                enabledEmail = 'true'
            } else if (enabledMfaType == 'phone') {
                enabledSMS = 'true'
            }
        })

        telemetry.identify(userId, {
            MFA_SMS_Enabled: enabledSMS,
            MFA_Email_Enabled: enabledEmail,
            MFA_totp_Enabled: enabledTotp,
        })
    }
}

const trackClickEvent = (
    buttonName: string,
    clickAction: string,
): void => {
    trackEvent(clickAction, `My Account Security ${buttonName} button clicked`)
}

export const trackLinkClickEvent = (
    link: string,
): void => {
    trackEvent(link, `My Account Security information link clicked`)
}

const trackEvent = (
    clickAction: string,
    eventName: string,
): void => {
    const segmentTrackParam: TrackProperties = {
        action: `Click ${clickAction}`,
        userId: getUserId(),
        myobVisitorId: getMyobVisitorId(),
        timestamp: new Date(Date.now()).toISOString(),
        event: eventName,
        url: config.callback,
        page: 'Security',
    }
    telemetry.segmentTrack(eventName, segmentTrackParam)
}