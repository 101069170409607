import {ArrowRightIcon, OpenExternalLinkIcon, Box, Card, Columns, Heading, Text} from "@myob/myob-widgets";
import {trackLinkClickEvent} from "../helper/telemetry-helper";

function trackLinkClick(heading) {
    trackLinkClickEvent(heading)
    return false
}

export const CardComponent = ({href, heading, children}) => (
    <Card style={{height: "277px"}}
          header={
              <Card.Header child={
                  <a href={href} target="_blank" onClick={() => trackLinkClick(heading)}>
                      <Box display="flex">
                          <Heading level="3" marginBottom="clear">{heading}&nbsp;</Heading>
                          <ArrowRightIcon color="brand"/>
                      </Box>
                  </a>
              }/>}
          body={<Card.Body child={
              <Box>
                  {children}
              </Box>
          }/>}
    />
);

export default function AdditionalInformation() {
    return <Box>
        <Columns>
            <Columns.Column span={["12", "12", "4", "4", "4"]}>
                <Box marginBottom="sm">
                    <CardComponent
                        href="https://www.myob.com/support/login-and-2fa"
                        heading="About authentication">
                        <Text>Two-Factor Authentication (2FA), sometimes called Multi-Factor Authentication (MFA).</Text>
                        <Text>How to set it up, and how it protects you and your data.</Text>
                    </CardComponent>
                </Box>
            </Columns.Column>
            <Columns.Column span={["12", "12", "4", "4", "4"]}>
                <Box marginBottom="sm">
                    <CardComponent
                        href="https://www.myob.com/support/billing-and-account/protecting-yourself-against-phishing"
                        heading="Protect against phishing">
                        <Text>Tips and recommendations to help you recognise and avoid phishing attacks.</Text>
                    </CardComponent>
                </Box>
            </Columns.Column>
            <Columns.Column span={["12", "12", "4", "4", "4"]}>
                <Box marginBottom="sm">
                    <CardComponent
                        href="mailto:securityincidents@myob.com"
                        heading="Report a security concern">
                        <Text>If you receive a suspicious email or notice unexpected activity, contact us
                            immediately at
                            securityincidents@myob.com.</Text>
                        <Text>Provide details of the suspicious activity and we'll investigate your concern and
                            take
                            action.</Text>
                    </CardComponent>
                </Box>
            </Columns.Column>
        </Columns>
        <Box display="flex" paddingTop="md">
            <OpenExternalLinkIcon color="brand"/>
            &nbsp;
            <a href="https://www.myob.com/support/security" target="_blank"
               onClick={() => trackLinkClick("More about security at MYOB")}>More about security at MYOB</a>
        </Box>
    </Box>
}