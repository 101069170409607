import { MfaDetail } from "../../../types";

export const filterEnabledMFATypes = (mfaDetails: MfaDetail[]): string[] => {
    let enabledMFAs: string[] = []

    mfaDetails.forEach((mfa) => {
        if (mfa.confirmed && enabledMFAs.indexOf(mfa.type) == -1) {
            enabledMFAs.push(mfa.type)
        }
    })

    return enabledMFAs
}