import { PageHead, Card, HomeIconIcon, IconButton, RightChevronIcon, Tooltip, Text, Columns, Box } from "@myob/myob-widgets"
import { useNavigate } from "react-router-dom";

export default function Integrations() {
    let navigate = useNavigate(); 

    const redirectToIntegrationsPage = () =>{ 
        navigate("/integration");
    }

    const integrationTableData = {
        type: 'password',
        methodName: <Box><Tooltip triggerContent={<HomeIconIcon color="neutral" style={{ verticalAlign: "sub" }} />}>Password</Tooltip>&nbsp;&nbsp;<Text as="strong">Third-party integrations</Text></Box>,
        description: <Text tone='neutral' as='span'>Control which apps have access to your MYOB data</Text>,
        displayName: 'Password'
    };

    return <Card
            header={<Card.Header
                child={<PageHead title="Integrations" />}/>}
            body={<Card.Body child={
                <>
                    {
                        <>
                            {
                                <Box
                                    display="flex"
                                    flexDirection={[
                                    'column', 
                                    'column',
                                    'row',    
                                    'row',    
                                    'row',    
                                    ]}
                                    columnGap={[
                                    null, 
                                    null, 
                                    "sm", 
                                    "sm",
                                    "sm", 
                                    ]}
                                    rowGap={[
                                    "lg", 
                                    "sm", 
                                    null, 
                                    null, 
                                    null, 
                                    ]}
                                >
                                    <Box flexGrow="1">
                                        <Columns alignY="center">
                                            <Columns.Column span={["12", "12", "3", "3", "3"]}>
                                                <Box>{integrationTableData.methodName}</Box>
                                            </Columns.Column>
                                            <Columns.Column span={["12", "12", "9", "9", "9"]}>
                                                <Columns alignY="center">
                                                    <Columns.Column span="10">
                                                    <Box>{integrationTableData.description}</Box>
                                                    </Columns.Column>
                                                    <Columns.Column span="2">
                                                        <Box textAlign="right">
                                                            <IconButton
                                                                data-testid="integration-button"
                                                                icon={<RightChevronIcon />}
                                                                onClick={redirectToIntegrationsPage}/>
                                                        </Box>
                                                    </Columns.Column>
                                                </Columns>
                                            </Columns.Column>
                                        </Columns>
                                    </Box>
                                </Box>
                            }
                        </>
                    }
                </>
            } />}
        />
}

