import {useEffect, useState} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from "jwt-decode";
import { Alert, FormTemplate, PageHead, Spinner } from "@myob/myob-widgets"
import ChangePassword from './components/change-password'
import EnrollMfa from "./components/enroll-mfa"
import { IdToken } from "../types";
import { ResumableAppState } from "./root.component";
import { telemetry } from '@my-account/tools';
import { getMyobVisitorId } from "./helper/tools";
import AdditionalInformation from "./components/additional-info";
import Integrations from './components/integrations';
import config from "./config";

export type NotificationProps = Readonly<{
    setNotification: React.Dispatch<React.SetStateAction<{}>>;
}>;

function App({ appState }: { appState: ResumableAppState }) {
    const { isLoading, isAuthenticated, error, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [notification, setNotification] = useState({display: false, message: '', tone: 'success'})

    function isSegmentInitialized() {
        return window.analytics == null;
    }

    useEffect(() => {
        console.debug(isLoading, isAuthenticated, appState)
        if (error) {
            console.error(error)
        }
        if (!isLoading && !isAuthenticated) {
            const token = localStorage.getItem('ldal.id_token')
            const username = token ? jwtDecode<IdToken>(token).username : "";
            loginWithRedirect({ authorizationParams: { login_hint: username } })
        }

        if (isSegmentInitialized()) {
            telemetry.initSegment()
        }
        telemetry.page('My Account Security Page', {
            title: 'Security',
            myobVisitorId: getMyobVisitorId(),
        });
    }, [isLoading, isAuthenticated])

    return (
        <div style={{ maxWidth: '990px', margin: '0 auto' }}>
            {
                isLoading || !isAuthenticated
                    ? <Spinner size="large" isLoading />
                    : <FormTemplate alert={notification.display &&
                        <Alert tone={notification.tone}
                               onDismiss={() => setNotification({display: false, message: '', tone: 'success'})}>
                            {notification.message}
                        </Alert>}
                                    sticky="none" pageHead={<PageHead title="Account security"/>}>
                        <ChangePassword setNotification={setNotification}/>
                        <EnrollMfa appState={appState} setNotification={setNotification}/>
                        <Integrations/>
                        <AdditionalInformation/>
                    </FormTemplate>
            }
        </div>
    )
}

export default App;
