import {
    AddIcon,
    Button,
    ButtonRow,
    Card,
    CommentsIcon,
    PageHead,
    Box,
    Spinner,
    Tooltip,
    MailIcon,
    MobileIcon,
    Separator,
    Text,
    Dropdown,
    MoreIcon,
    Label,
    IconButton,
    Columns,
    SecurityIcon,
} from '@myob/myob-widgets'
import React, {useEffect, useState} from "react";
import {useAuth0} from '@auth0/auth0-react'
import bff from '../effects/bff-client'
import {ChangeMfaResponse, DeleteMfaResponse, EnrollMfaResponse, MfaListResponse} from "../../types";
import {any, filter, includes, length, not, propEq} from 'ramda'
import config from '../config'
import type {ResumableAppState} from '../root.component'
import {ModalBox} from "./remove-pop-up-modal";
import {NotificationProps} from "../app";
import {identifyMFA, trackMFAClickEvent, trackMFAConfirmationClickEvent} from "../helper/telemetry-helper";
import {filterEnabledMFATypes} from "./helper/enroll-mfa-helper"
import { parseISO, format } from 'date-fns';

const REQUIRE_MFA = 'http://schemas.openid.net/pape/policies/2007/06/multi-factor'

const mfaSuccessNotification = {
    display: true,
    message: 'Two-factor authentication email sent',
    tone: 'success'
}
const dangerNotification = {display: true, message: 'An error occurred, please try again later', tone: 'danger'}

type EnrollMfaProps = Readonly<{
    appState: ResumableAppState;
    setNotification: NotificationProps;
}>;
export default function EnrollMfa({appState, setNotification}: EnrollMfaProps) {
    const [loading, setLoading] = useState(true)
    const [methods, setMethods] = useState([])
    const [userIdp, setUserIdp] = useState('')
    const [idsActive2fa, setIdsActive2fa] = useState('')
    const {loginWithRedirect, getAccessTokenSilently: getAccessToken} = useAuth0()
    const [modalAction, setModalAction] = useState({display: false, name: '', type: '', typeName: '',msg: ''})

    async function handleEnroll2fa() {
        try {
            const token = await getAccessToken()
            const resp = await bff.post<EnrollMfaResponse>('/enroll-mfa', null, { headers: { Authorization: `Bearer ${ token }`}})
            const body = resp.data
            const redirect = body.redirectUrl
            if(redirect) {
                window.location.href = redirect
            }
            setNotification(mfaSuccessNotification)
        } catch(e) {
            setNotification(dangerNotification)
        }
    }

    async function updateMfa(token: string) {
        return bff.post<ChangeMfaResponse>('/change-mfa', {}, {headers: {Authorization: `Bearer ${token}`}}).then(() => {
            return loginWithRedirect({
                appState: {action: 'INIT'} as ResumableAppState,
                authorizationParams: {acr_values: REQUIRE_MFA, redirect_uri: config.callback}
            })
        })
    }

    async function handleChangeMyobId2faClick(event?: Event) {
        setLoading(true)
        try {
            await getAccessToken({authorizationParams: {acr_values: REQUIRE_MFA}})
                .then(updateMfa)
                .catch(e => {
                        if (event) {
                            return loginWithRedirect({
                                appState: {action: 'RESUME_CHANGE_MFA'} as ResumableAppState,
                                authorizationParams: {acr_values: REQUIRE_MFA, redirect_uri: config.callback}
                            })
                        }
                        throw "should not reach here when event is empty, something went wrong, try refresh..."
                    }
                )
        } catch (e) {
            console.error("error occur while handling mfa change click:", e, "from event:", event)
            setNotification(dangerNotification)
        }
    }

    async function addMFAMethod(type: string, typeName: string, eventType: string = 'Add') {
        if (eventType == 'Add') {
            trackMFAClickEvent('Add', typeName)
        }
        const event = new Event('click')
        setLoading(true)
        setNotification({display: false, message: '', tone: 'success'})
        try {
            await getAccessToken({authorizationParams: {acr_values: REQUIRE_MFA}})
                .then(() => {
                    return loginWithRedirect({
                        appState: {action: 'INIT'} as ResumableAppState,
                        authorizationParams: {acr_values: REQUIRE_MFA, redirect_uri: config.callback, "ext-prompt":"enroll_authenticator", "ext-enroll_amr_values": type=="totp"?"otp":type}
                    })
                })
        } catch (e) {
            console.error("error occur while handling mfa change click:", e, "from event:", event)
            setNotification(dangerNotification)
        }
    }

    function isUserMfaEnabled(): boolean {
        if (userIdp === 'IDS') {
            return length(filter(({id}) => not(includes('null', id)), methods)) > 0
        }
        return methods.length > 0
    }


    async function fetchMfa() {
        try {
            const token = await getAccessToken()
            const resp = await bff.get<MfaListResponse>('/mfa', {headers: {Authorization: `Bearer ${token}`}})
            const data = await resp.data
            setMethods(data.methods)
            setUserIdp(data.userIdp)
            setIdsActive2fa(any(propEq('id', 'email|null'))(data.methods) ? 'totp' : 'email')
            setLoading(false)
            identifyMFA(filterEnabledMFATypes(data.methods))
        } catch (e) {
            setNotification(dangerNotification)
        }
    }
    useEffect(() => {
        setLoading(true)
        if (appState.action === 'RESUME_CHANGE_MFA') {
            console.debug("resume mfa change clicking from appState...: ", appState)
            handleChangeMyobId2faClick()
        }
        else {
            fetchMfa()
        }
    }, [appState])

    async function handleToggleMFA(mfaType: string, isReset: boolean) {
        setLoading(true)
        const method = methods.find(method => method.type === mfaType)
        const token = await getAccessToken()
        try {
            const res = await bff.delete<DeleteMfaResponse>(`/mfa/${method.id}`, { headers: { Authorization: `Bearer ${token}` } })
            if (res.data.status !== 'ok') {
                setLoading(false)
                setNotification(dangerRemoveMfaNotification)
                return
            }
            if(isReset){
                addMFAMethod(mfaType, method.typeName, 'Reset')
                return
            }
            await fetchMfa()
            setNotification(successRemoveMfaNotification(mfaType))
        } catch (e) {
            console.log('error removing mfa: ', e)
            setLoading(false)
            setNotification(dangerRemoveMfaNotification)
        }
    }

    const handleOnAction = async () => {
        const methodType = modalAction.type;
        const isReset = modalAction.name.includes('Reset')
        const operation = isReset ? 'Reset' : 'Remove'
        trackMFAConfirmationClickEvent(operation, modalAction.typeName)

        setModalAction({display: false, name: '', type: '', typeName: '',msg: ''})
        setNotification({display: false, message: '', tone: 'success'})
        await handleToggleMFA(methodType,isReset);
    };

    const handleGoBack = () => {
        setModalAction({display: false, name: '', type: '', typeName: '',msg: ''});
    };

    const handleEnrollFirstMFA = async () => {
        trackMFAClickEvent('Add', 'First')
        await handleEnroll2fa()
    }
    const handleDropdownSelect = (actionType,row) => {
        trackMFAClickEvent(actionType, row.typeName)
        let displayName = actionType + ' ' + row.displayName
        let msg = ''
        if(actionType == 'Remove'){
            if(row.type == 'email'){
                msg = 'As email authentication is being phased out, this method will no longer be available once removed. You can still log in securely using other authentication methods.'
            }
            else
                msg = `This removes using the ${row.displayName} when you log in. You can still login securely using other authentication methods`
        }
        else {
            msg = row.type == 'phone' ? `You'll need to provide a new phone number` : `You'll need to set up your authenticator again`
        }
        setModalAction({display: true, name: displayName, type: row.type, typeName: row.typeName, msg: msg})
    }

    const renderDropdown = (row) => {
        const items = row.type !== "email"
            ? [
                <Dropdown.Item key="actionRemove" label="Remove" value="Remove" align="right"/>,
                <Dropdown.Item key="actionReset" label="Reset" value="Reset" align="right"/>
            ]
            : [
                <Dropdown.Item key="actionRemove" label="Remove" value="Remove" align="right"/>
            ];

        return (
            <Dropdown
                items={items}
                onSelect={(actionType) => handleDropdownSelect(actionType, row)}
                placement="bottom-left"
                toggle={
                    <Dropdown.Toggle variant="ghost" aria-label="actionRemove" size="xs" icon={<MoreIcon/>}/>
                }
            />
        );
    }
    
    return <>
        <div data-testid="security-page">
            {modalAction.display && (
                <ModalBox
                    onAction={handleOnAction}
                    onGoBack={handleGoBack}
                    title={`${modalAction.name}`}
                    message={`${modalAction.msg}`}
                />
            )}
        </div>
        <Card
            header={<Card.Header child={<PageHead title="Authentication"/>}/>}
            body={<Card.Body child={
                <>
                    {
                        loading
                            ? null
                            :
                            <>
                                {
                                    isUserMfaEnabled()
                                        ?
                                        <>
                                            {multiMFATableData
                                                .filter(row => row.type!="email" || row.type=="email" && isEnabledMethod(row.type,methods))
                                                .map(row => (
                                                    <>
                                                        <Box
                                                            display="flex"
                                                            flexDirection={[
                                                            'column', 
                                                            'column',
                                                            'row',    
                                                            'row',    
                                                            'row',    
                                                            ]}
                                                            columnGap={[
                                                            null, 
                                                            null, 
                                                            "sm", 
                                                            "sm",
                                                            "sm", 
                                                            ]}
                                                            rowGap={[
                                                            "lg", 
                                                            "lg", 
                                                            null, 
                                                            null, 
                                                            null, 
                                                            ]}
                                                        >
                                                            <Box flexGrow="1">
                                                                <Columns alignY="center" data-testid={row.type} key={row.id}>
                                                                    <Columns.Column span={["12", "12", "3", "3", "3"]}>
                                                                        <Box>{row.methodName}</Box>
                                                                    </Columns.Column>
                                                                    <Columns.Column span={["12", "12", "7", "7", "7"]}>
                                                                        <Box>{getMFADescription(row, methods)}</Box>
                                                                    </Columns.Column>
                                                                    <Columns.Column span={["12", "12", "2", "2", "2"]}>
                                                                        <Columns alignY="center">
                                                                            <Columns.Column span="6">
                                                                                <Box>{isEnabledMethod(row.type,methods) ? <Label type="boxed" tone="success">Enabled</Label> :""}</Box>
                                                                            </Columns.Column>
                                                                            <Columns.Column span="6">
                                                                                <Box textAlign="right">
                                                                                    {
                                                                                        !isEnabledMethod(row.type,methods)
                                                                                            ?
                                                                                            <Button type="link" icon={<AddIcon/>} onClick={() => { addMFAMethod(row.type, row.typeName)} }>Add</Button>
                                                                                            :
                                                                                            <> {methods.filter(method => method.confirmed).length > 1
                                                                                                ? renderDropdown(row)
                                                                                                : <IconButton
                                                                                                    icon={<MoreIcon />}
                                                                                                    aria-label="actionRemoveDisabled"
                                                                                                    disabled
                                                                                                    tooltip={{
                                                                                                        text: "Cannot remove if you have only one method enabled",
                                                                                                        placement: "bottom",
                                                                                                    }}/>
                                                                                            }
                                                                                            </>
                                                                                    }
                                                                                </Box>
                                                                            </Columns.Column>
                                                                        </Columns>
                                                                    </Columns.Column>
                                                                </Columns>
                                                            </Box>
                                                        </Box>
                                                        <Separator style={{ marginTop: '16px', marginBottom: '16px' }}/>
                                                    </>
                                                ))}
                                        </>
                                        :
                                        <>
                                            <dt>
                                                <Columns alignY="center">
                                                    <Columns.Column span="3" >
                                                        <Box>
                                                            <Tooltip triggerContent={<SecurityIcon color="brand" style={{verticalAlign: "sub"}}/>}>Two-Factor Authentication</Tooltip>&nbsp;&nbsp;
                                                            <Text as="strong">Two-Factor Authentication</Text>
                                                        </Box>

                                                    </Columns.Column>
                                                    <Columns.Column span="9" alignY="center">
                                                        <Text tone='neutral' as='span'>Two-factor authentication adds a layer of security to your account by using more than just your password to log in</Text>
                                                    </Columns.Column>
                                                </Columns>
                                            </dt>
                                            <ButtonRow>
                                                <Button tone="success" onClick={handleEnrollFirstMFA}>Add two-factor authentication</Button>
                                            </ButtonRow>
                                        </>
                                }
                            </>
                    }
                </>
            }/>}
            footer={
                loading ?
                    <Spinner size="medium"/>
                    :
                    <ButtonRow>
                    </ButtonRow>
            }
        />
        {methods.filter(methods => methods.type === 'recovery-code' && methods.confirmed).map(recoverycode => {
            return <Card
                data-testid="recovery-code-card"
                header={<Card.Header child={<PageHead title="Backup codes"/>}/>}
                body={<Card.Body child={
                    <>
                        Use a backup code if your authenticator app doesn’t work or you don't have access to your device.
                        <ButtonRow>
                            <a href={recoverycode.actionLink}><Button>Show backup codes</Button></a>
                        </ButtonRow>
                    </>
                }/>}
            />
        })}
    </>
}

const isEnabledMethod = (type: String, methods) => {
    return methods?.find(method => method.type==type)?.confirmed;
}

const getMFADescription = (row, methods) => {
    const method = methods.find(method => method.type == row.type);
    const phoneNumber = method?.phone_number;
    return phoneNumber
        ? <Text tone='neutral' as='span'>You'll receive one time codes at {phoneNumber}{getLastUsed(method)}</Text>
        : <Text tone='neutral' as='span'>{row.description}{getLastUsed(method)}</Text>
}

const getLastUsed = (method) => {
    const lastUsed = method?.last_auth_at?parseISO(method.last_auth_at):null;
    return lastUsed?`. Last used ${format(lastUsed,'dd/MM/yy')}`:''
}

const multiMFATableData = [
    {
        id: '1',
        type:'totp',
        methodName: <><Tooltip triggerContent={<MobileIcon color="neutral" style={{ verticalAlign: "sub" }}/>}>Authenticator
            app</Tooltip>&nbsp;&nbsp;<Text as="strong">Authenticator app</Text></>,
        description: 'Verify an app like Google Authenticator',
        displayName: 'authenticator app',
        typeName: 'Authenticator app',
    },
    {
        id: '2',
        type:'phone',
        methodName: <><Tooltip triggerContent={<CommentsIcon color="neutral" style={{ verticalAlign: "sub" }}/>}>SMS</Tooltip>&nbsp;&nbsp;<Text as="strong">SMS</Text></>,
        description: 'We use SMS verification at log in to make sure its you',
        displayName: 'SMS authentication',
        typeName: 'SMS',
    },
    {
        id: '3',
        type:'email',
        methodName: <><Tooltip triggerContent={<MailIcon color="neutral" style={{ verticalAlign: "sub" }}/>}>Email</Tooltip>&nbsp;&nbsp;<Text as="strong">Email</Text></>,
        description: 'Use a backup email to verify you when logging in',
        displayName: 'email authentication',
        typeName: 'Email',
    },
];

const successRemoveMfaNotification = (type: string) =>
{
    const method = multiMFATableData.find(method => method.type === type)
    return {
        display: true,
        message: `${method.displayName.charAt(0).toUpperCase() + method.displayName.slice(1)} removed successfully`,
        tone: 'success'
    }
}

const dangerRemoveMfaNotification = { display: true, message: 'An error occurred, please try again later', tone: 'danger' }
